import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'

type Props = {
    app:any;
}

const EZUGISEO = (props: Props) => {
    const { app :{brandId}}=props;
    const domainName = brandId === 31 || brandId === 32 ? 'YOLO247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7':  'Betdaily' ;
    
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>EZUGI</h1></label>
                    <div className="accordion__content">
                        <h2>A Brief History of Ezugi Gaming</h2>
                        <p>Established in 2012, Ezugi provides B2B live casino games and live lottery gaming solutions to online casinos worldwide. It is one of the fastest-moving companies for setting up clients in emerging markets. It successfully retains customers by providing localised content tailor-made for the local audience.</p>
                        <p>Ezugi has a wide range of live casino games, with dealers streaming live from their studios. Moreover, they also provide new-age casino games like Roulette, Andar Bahar, and Blackjack.</p>
                        <p>Over the years, Ezugi's clients have been happy to host some of the most popular live games, nothing short of thrillers. They have complimented this cutting-edge technology with efficient 24*7 customer service that responds to queries promptly. Moreover, Ezugi is fully licensed by some top regulators. They are as follows:</p>
                        <ul>
                            <li>UK Gambling Commission</li>
                            <li>Malta Gaming Authority</li>
                            <li>The Isle of Man Gambling Supervision Commission</li>
                            <li>NJ Division of Gaming Enforcement</li>
                            <li>Gaming Control Authority</li>
                        </ul>
                        <p>Ezugi is, without a doubt, one of the most credible gaming providers in the world.</p>

                        <h2>Ezugi Live Casino | A Plethora of Exciting Casino Games On {domainName}</h2>
                        <p>Ezugi provides six live casino games on {domainName}. Live casino games are quite different from physical casino games. However, live casino games always stand out since you can enjoy them from home. Let us go through an overview of the Ezugi games available on {domainName}.</p>

                        <h3>Live Roulette:</h3>
                        <p>Ezugi Live Roulette is based on traditional Roulette, which was quite prevalent in the 18th century.</p>

                        <h3>Live 32 Cards:</h3>
                        <p>As the name suggests, this game is played with a pack of 32 cards whose values range from 6 to 13. The whole idea is to determine which player has the highest card total.</p>

                        <h3>Live Andar Bahar:</h3>
                        <p>This is another game in the collection of Ezugi Live Casino, and the main objective is to predict on which side the joker card will appear: Andar (inside) or Bahar (outside).</p>

                        <h3>Online Baccarat:</h3>
                        <p>Ezugi gaming's next live casino game is online baccarat. The game is straightforward. A player needs to predict if the banker or the player will have the closest hand to 9.</p>

                        <h3>Live Blackjack:</h3>
                        <p>You aim to beat the dealer in this {domainName} Ezugi live casino game. A hand that has a value closer to 21 is the winner.</p>

                        <h3>Live Bet On Numbers:</h3>
                        <p>This Ezugi live casino game is based on a lottery-style model and has a wide variety of betting options.</p>

                        <p>These exciting games offer plenty of thrill and rewards in equal measure for gaming enthusiasts.</p>

                        <h2>Why Play Ezugi Live Casino On {domainName}?</h2>
                        <p>{domainName} has many exclusive features that make it one of the favourites among online gaming enthusiasts, especially those new to the betting industry. There are many reasons, which we will take a look at.</p>

                        <h3>User-Friendliness:</h3>
                        <p>Ezugi live casino games on {domainName} are user-friendly. It helps the new users, as they no longer have to think twice before deciding.</p>

                        <h3>Easy to Navigate:</h3>
                        <p>Navigating the {domainName} site and finding Ezugi live games is easy. A beginner in casino games would enter a new website and struggle to find the proper way to play a live casino game. Here, the easy navigation of {domainName} comes into play, as they have well-highlighted subsections.</p>
                        <p>You can enter the Live Casino section under Casino. Under the Live section, you will find multiple game providers on the left-hand side. Click on Ezugi and start playing the thrilling games.</p>

                        <h3>Wide Range of Payment Methods:</h3>
                        <p>Unlike some competitors, {domainName} provides ease of transaction. You can deposit money using a payment method of your choice. It could be e-wallets, it could be UPIs, name it. Similarly, you can take out the money whenever you want.</p>

                        <h3>Round-the-clock Customer Service:</h3>
                        <p>The Ezugi gaming experience on {domainName} is seamless thanks to efficient customer service. Irrespective of the type of query or issue, customer service will resolve anything at the earliest.</p>

                        <h3>Real-world Feel:</h3>
                        <p>Live casinos by Ezugi Gaming ensure a dealer is available 24*7. This dealer, who is in a studio, would set up the game and keep talking to you, acting as a comrade in a common cause. It is as close as sitting in a real casino can get.</p>

                        <h3>Excellent Graphics:</h3>
                        <p>{domainName} beats its competitors when highlighting minute details about the live casinos. For instance, it will display the minimum bet required to start the game. It also provides detailed information about a particular game, like the rules, which helps the players prepare a strategy.</p>
                        <p>Those mentioned above are some exciting features that steer gaming enthusiasts to {domainName} to play Ezugi Games.</p>

                        <h2>How to Get Started at {domainName} Ezugi Live Casino</h2>
                        <p>Now that you know almost everything about Ezugi gaming, do you want to try your hands at the Ezugi games? Here's how to play Ezugi games on {domainName}.</p>
                        <ol>
                            <li>Visit {domainName}.com or download the {domainName} app</li>
                            <li>Visit Yolo247.net or download the Yolo247 app</li>
                            <li>Hover over the Casino section, and click on Live Casino</li>
                            <li>Once you enter this section, you will see many game providers on the left-hand side</li>
                            <li>Select Ezugi and start playing and betting on games of your choice</li>
                        </ol>

                        <h2>FAQs about Ezugi Live Casino</h2>
                        <p><strong>Can betting strategies help win big in Ezugi Live Casino Games?</strong></p>
                        <p>Yes, strategies will help you win live casino games. Make sure that you develop a plan before placing bets.</p>

                        <p><strong>Can I talk to live dealers?</strong></p>
                        <p>Yes. You can use the chat box to get your point of view across. Some games, like Live Andar Bahar and Online Baccarat, have dealers who keep the conversation going, giving you a land-based casino vibe.</p>


                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}


export default withTheme(withAppContext(EZUGISEO));