import React,{Component} from 'react';
import { Link } from "react-router-dom";
import config from 'src/config/config';
import styled, { css, withTheme } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';
import {color} from '../../sass-files/variable';
// import GamstopLogo from 'src/images/gamestop_png.png';
// import BegambleAwareLogo from 'src/images/be-gamble-awarewhite.png';
// import GameCareLogo from 'src/images/gamcare_logo.png';
import Intl from '../common/Intl'
import { Tittle as FooterTittle } from '../udb/commonStyle';
import { withAppContext } from 'src/store/initAppContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

function FooterContainer(props: any) {
    const { app: { brandId }, theme } = props
    const { config: { showFooterNetbanking, showFooterastropay,showBlog,showNews,showAffiliate,showPress,ShowPrincipalAwardLogo,showjoshlogo, showOnlyOnFomo } } = theme;
    const hostname = window.location.hostname;
 
    return (
            <Container>
                <CopyRight>
                {ShowPrincipalAwardLogo &&
                    <>
                        {(showOnlyOnFomo) ? (
                            <SponsersWrapper>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@PrincipalSponsors" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/principalSponsorsFomodesk.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="principal sponsors" />
                                </LogoContain>
                                <CenterLine></CenterLine>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@PrinciplePartner" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/principlePartnerFomoNew.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Principle Partner" />
                                </LogoContain>
                                <CenterLine></CenterLine>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@AssociatePartner" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/associatePartnerFomoNew.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Associate Partner" />
                                </LogoContain>
                                <CenterLine></CenterLine>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@Associatesponsors" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/associateSponsorFomo.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Associate sponsor" />
                                </LogoContain>

                            </SponsersWrapper>
                        ) : (
                            <SponsersWrapper>
                                <LogoContain>
                                    <Tittle><Intl langKey="FOOTER@PrincipalSponsors" /></Tittle>
                                    <FooterLogoWrap>
                                        <div>
                                            <LazyLoadImage src={`${config.imageV3Host}/static/media/knightRidersSponcerLogo.svg`} effect="none" placeholder={<PlaceholderImage />} alt="yolo sponsorship" />
                                        </div>
                                        <div>
                                            <LazyLoadImage src={`${config.imageV3Host}/static/media/DubaiCapitalsLogoNew.png`} effect="none" placeholder={<PlaceholderImage />} alt="yolo sponsorship" />
                                            <LazyLoadImage src={`${config.imageV3Host}/static/media/yoloGames.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="yolo sponsorship" />
                                        </div>
                                    </FooterLogoWrap>
                                </LogoContain>
                                <CenterLine></CenterLine>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@EngagementPartner" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/GwaliorCheetahsEngSponsor.png`} effect="none" width="70%" placeholder={<PlaceholderImage />} alt="GwaliorCheetahs Sponsor" />
                                </LogoContain>
                                <CenterLine></CenterLine>
                                <LogoContain $propsAdd>
                                    <Tittle><Intl langKey="FOOTER@AwardsAndRecognition" /></Tittle>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/awardWinnerLogoFooter.svg`} effect="none" placeholder={<PlaceholderImage />} alt="yolo award" />
                                </LogoContain>
                            </SponsersWrapper>
                        )}
                    </>
                }
                    <Tittle><Intl langKey="FOOTER@GAMINGPARTNERS"/></Tittle>
                    <Link to="/online-casino-games/ezugi-live-casino"><LazyLoadImage src={`${config.imageV3Host}/static/media/ezugiLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/ag"><LazyLoadImage src={`${config.imageV3Host}/static/media/aGLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/betgames"><LazyLoadImage src={`${config.imageV3Host}/static/media/betGamesLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/evolutiongaming"><LazyLoadImage src={`${config.imageV3Host}/static/media/evolutionGamesNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    {/* <Link to="/online-casino-games/betsoft"><LazyLoadImage src={`${config.imageV3Host}/static/media/betSoftLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    <Link to="/online-casino-games/slotGames/netent"><LazyLoadImage src={`${config.imageV3Host}/static/media/netentLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/tvbet"><LazyLoadImage src={`${config.imageV3Host}/static/media/tvbetLogoNew.png`}effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/superspade"><LazyLoadImage src={`${config.imageV3Host}/static/media/superSpadeLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    {/* <Link to="/online-casino-games/slotGames/pragmatic"><LazyLoadImage src={`${config.imageV3Host}/static/media/pragmaticplayCasinosNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    {/* <Link to="/online-casino-games/slotGames/spinmatic"><LazyLoadImage src={`${config.imageV3Host}/static/media/spinmaticLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                  <Link to="/online-casino-games/supernowa"><LazyLoadImage src={`${config.imageV3Host}/static/media/supernowaLogoNew.png`} effect="none" placeholder={<PlaceholderImage />} visibleByDefault={false} /></Link>
                   {/* <Link to="/online-casino-games/sexygaming"><LazyLoadImage src={`${config.imageV3Host}/static/media/sexygaminglogo.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    <Link to="/sportsbook"><LazyLoadImage src={`${config.imageV3Host}/static/media/BTILogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/online-casino-games/7MOJOS"><LazyLoadImage src={`${config.imageV3Host}/static/media/7mojosIcon.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/instant-games/SPRIBE"><LazyLoadImage src={`${config.imageV3Host}/static/media/spribe_logo_footer.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                </CopyRight>

                <CopyRight>
                    <Tittle><Intl langKey="FOOTER@PAYMENTMETHODS"/></Tittle>
                    {showFooterNetbanking && <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/internetBanking.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>}
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/upiLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/gpayLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/paytmLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                   {showFooterastropay && <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/astropayLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>}
                </CopyRight>

                <CopyRight>
                    <Tittle><Intl langKey="FOOTER@CONNECTWITHUS"/></Tittle>
                    <div className="socialMediaWrapper">
                    <a href={brandId==33 || brandId ==34 ? "https://www.facebook.com/baaziaddaofficial" : (brandId == 39 || brandId == 40) ? 'https://www.facebook.com/profile.php?id=61559594625539' :'https://www.facebook.com/OfficialYolo247'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/FacebookIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                   {showjoshlogo && <a href={brandId==33 || brandId ==34 ? 'https://share.myjosh.in/profile/837351a9-76e6-489e-94e3-09175ecac023?u=0xf6baa3aea1f758f0' : (brandId == 39 || brandId == 40) ? 'https://share.myjosh.in/profile/01c3660a-3f8b-4978-91a7-cefb269971d8?u=0x403e9132e9724a50' : 'https://share.myjosh.in/profile/6c4e8b3e-8b43-4b6e-a775-daa8ec3eec65?u=0x740e7c68af536d14' }target="_blank">
                            <LazyLoadImage src={`${config.imageV3Host}/static/media/joshAppIcon.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>}
                        <a href={brandId==33 || brandId ==34 ? "https://www.instagram.com/baaziadda_official/" : (brandId == 39 || brandId == 40) ? 'https://www.instagram.com/fomo7_india/' : 'https://www.instagram.com/yolo247official/'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/InstIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                        <a href={brandId==33 || brandId ==34 ? "https://twitter.com/baazi_adda/" : (brandId == 39 || brandId == 40) ? 'https://x.com/FOMO7official' :'https://twitter.com/Yolo247Official'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/TwitterIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                        <a href={brandId==33 || brandId ==34 ? "https://www.youtube.com/@BaaziAdda-mh6pz " : (brandId == 39 || brandId == 40) ? 'https://www.youtube.com/channel/UCt4FXXC494EcHrA6wtnqcng' :'https://www.youtube.com/@yolo247-q5o' } target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/YoutubeIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                        <a href={brandId==33 || brandId ==34 ? "https://t.me/Baaziadda_Group" : (brandId == 39 || brandId == 40) ? 'https://t.me/fomo7official' : 'https://t.me/Yolo247'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/TelegramIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                    </div>
                </CopyRight>

                <CopyRight>
                    <a href="https://www.gamstop.co.uk/"><LazyLoadImage src={`${config.imageV3Host}/static/media/gamestopLogo.png`} effect="none"  placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <a href="https://www.begambleaware.org/"><LazyLoadImage src={`${config.imageV3Host}/static/media/beAwareLogo.png`} effect="none"   placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <a href="https://www.gamcare.org.uk/"><LazyLoadImage src={`${config.imageV3Host}/static/media/gamcareLogo.png`} effect="none"  placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <Span><LazyLoadImage src={`${config.imageV3Host}/static/media/18PlusLogo.png`} effect="none"  placeholder={<PlaceholderImage/>}/></Span>
                    <Span><Intl langKey="FOOTER@COPYRIGHT"/></Span>
                </CopyRight>

                {/* <CopyRight>
                    Yolo247 is a brand managed by SNK Entertainment N.V. (10692), a Curacao registered company whose registered address is 7 Abraham De Veerstraat, Curacao. <br/> SNK Entertainment N.V. Limited is licensed and regulated in Curacao by the Gaming Curacao.
                </CopyRight> */}
                <NewLogoWrapper>
                     {/* <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/brand/6/icon/Gaming-Curacao-Logo.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/> */}
                </NewLogoWrapper>

                <PolicyWrapper>
                <Link to="/termsandconditions">	 <Intl langKey="FOOT@TERMSANDCONDITIONS" />  |  </Link>
                <Link to="/responsible-gambling">  <Intl langKey="FOOT@RESPONSIBLEGAMBLING" />  | </Link>
                <Link to="/privacy-policy">	  <Intl langKey="FOOT@PRIVACYPOLICY" />  | </Link>
                {/* <Link to="/disconnection-policy">  <Intl langKey="FOOT@DISCONNECTPOLICY" /> | </Link> */}
                <Link to="/kyc-policy">  <Intl langKey="FOOT@KYCPOLICY" />  | </Link>
                <Link to="/exclusion-policy">  <Intl langKey="FOOT@EXCLUSIONPOLICY" />  | </Link>
                <Link to="/aml-policy"> <Intl langKey="FOOT@AMLpolicy" /> |  </Link>
                <Link to="/aboutus">  <Intl langKey="FOOT@ABOUTUS" />  | </Link>
                <Link to="/faq">  <Intl langKey="FOOT@FAQ" />  | </Link>
                {showAffiliate && <Link to='/affiliate'><Intl langKey="Header@Affiliate" /></Link> }
               {showBlog && <a href={`https://${hostname}/blog/`}> | <Intl langKey="FOOT@blog" /> </a> }
               {showNews && <a href="https://www.yolo247.news/"> | <Intl langKey="FOOT@news" /> </a> }
               {showPress && <a href={`https://${hostname}/press/`}> | <Intl langKey="FOOT@Press" /> </a> }

                    {/* <Link to="/rules-regulations">  <Intl langKey="FOOT@RULES" /> |</Link>
                    <Link to="/restricted-territories">  <Intl langKey="FOOT@RESTRICTEDTERRITORIES" /> </Link> */}
                </PolicyWrapper>
            </Container>
    )
}

export default withTheme(withAppContext(FooterContainer));

//Styled Components

const CenterLine = styled.div`
    /* width: 2px; */
    height: 106px;
    padding: 0 1px;
    background: linear-gradient(180deg, #E6A238 0%, #FDEF9C 47.92%, #DB921A 100%);
    align-self: center;
`;

const LogoContain = styled.div<{ $propsAdd?: boolean }>`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;
    justify-content: center;

    img {
        width: 100px;

        ${(props) => props.$propsAdd && css`
            width: 202px;
        `}
    }
`;

const SponsersWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;
`;

const NonClick = styled.div`
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out 0s;
`;

const Tittle = styled(FooterTittle)`
    width: 100%;
    color: ${theme('palette.footerTittleColor')};;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
`;

const Container: any = styled.div`
    width: 100%;
    background: ${theme('palette.footerbgColor')};
    position: relative;
`;

const CopyRight = styled.div`
    width: 100%;
    display: flex;
	align-items:center;
	justify-content: center;
    flex-wrap: wrap;
    color: ${theme('palette.footerDefaultColor')};
    font-size: 15px;
    text-align: center;
	line-height: 20px;
	padding-left: 10px;
    padding: 30px;
    padding-bottom: 10px;
    margin: 0 auto;

	a {
        /* width: 122px; */
        width: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 5px;
		transition: all 0.2s ease-in-out 0s;
	}

	a:hover {
        background-color: ${theme('palette.footerLogoHoverBg')};
	}

    img {
        max-width: 100%;
        transition: all 0.2s ease-in-out 0s;
    }

    .socialMediaWrapper {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            width: 35px;
            height: 35px;
            margin: 0px 5px;
            display: flex;
            padding: 0;

            img {
                width: 100%;
                border: ${theme('palette.footerSocialMediaBorder')};
                border-radius: 20px;
            }

            :hover {
                background-color: transparent;

                img {
                    border: ${theme('palette.footerSocialMediaBorderHover')};
                }
            }
        }
    }
`;

const PolicyWrapper = styled.div`
    font-size: 15px;
	display: flex;
	justify-content:center;
	padding-bottom:20px;
	align-items: center;
	padding-left: 10px;
    width: 100%;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    a {
        color: ${theme('palette.footerPolicyTextColor')};
        font-size: 13px;
        opacity: 0.8;
        padding:5px;
        text-decoration: none;
        position: relative;

        :after {
            left: 0;
            bottom: -2px;
            position: absolute;
            width: 0%;
            margin: 0 auto;
            height: 1px;
            background: #FFFFFF;
            content: '';
            position: absolute;
            right: 0;
        }
    }

    a:hover {
            /* opacity: 1;
            -webkit-transition: all 0.2s ease 0s;
		    transition: all 0.2s ease-in-out 0s;
            text-decoration: underline; */

            :after {
                width: 90%;
                transition-duration: 0.5s;
                -webkit-transition-duration: 0.5s;
            }
        }
`;

const Span = styled.div`
  padding-right:10px;
  font-size:13px;
  text-transform: capitalize;
  
  img {
    width: 34px;
    height: 34px;
    margin: 0px 10px;
  }
`;

const NewLogoWrapper = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    & img {
        width: 140px;
        height: 60px;
        object-fit: contain;
    }
`;

const FooterLogoWrap = styled.div`
    display: flex;
    gap: 1.2em;
    justify-content: center;
    align-items: center;

    & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
