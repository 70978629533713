import { GET_EVENTS, GET_INNER_EVENTS } from '../graphql/events';
import { EEventMode, EEventTypeGroup, EEventTypeId, EEventTypeKey } from '../util/utilModel';

const YCB = require('ycb');

const masterConfig = {
	settings: ['master'],
	eventTypeKey: '',
	eventTypeGroup: '',
	eventMode: '',
	categoryIds: [1],
	name: '',
	displayName: '',
	langKey: '',
	gqlQuery: GET_EVENTS,
};

const configArray = [
	{
		dimensions: [
			{
				eventType: {
					[EEventTypeId.all]: null,
					[EEventTypeId.football]: null,
					[EEventTypeId.tennis]: null,
					[EEventTypeId.cricket]: null,
					[EEventTypeId.volleyball]: null,
					[EEventTypeId.horseracing]: null,
					[EEventTypeId.greyhoundracing]: null,
					[EEventTypeId.SPECIAL_FANCY]: null,
					[EEventTypeId.politics]: null
				},
			},
		],
	},
	masterConfig,
	{
		settings: [`eventType:${EEventTypeId.all}`],
		eventTypeKey: EEventTypeKey.football,
		eventTypeGroup: EEventTypeGroup.eventTable,
		eventMode: EEventMode.drawGame,
		categoryIds: [EEventTypeId.cricket, EEventTypeId.football, EEventTypeId.volleyball, EEventTypeId.tennis, EEventTypeId.SPECIAL_FANCY],
		name: 'ALL',
		displayName: 'ALL',
		langKey: 'ALL',
		gqlQuery: GET_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.football}`],
		eventTypeKey: EEventTypeKey.football,
		eventTypeGroup: EEventTypeGroup.eventTable,
		eventMode: EEventMode.drawGame,
		categoryIds: [EEventTypeId.football],
		name: 'football',
		displayName: 'Football',
		langKey: 'FOOTBALL',
		gqlQuery: GET_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.tennis}`],
		eventTypeKey: EEventTypeKey.tennis,
		eventTypeGroup: EEventTypeGroup.eventTable,
		eventMode: EEventMode.winLose,
		categoryIds: [EEventTypeId.tennis],
		name: 'tennis',
		displayName: 'Tennis',
		langKey: 'TENNIS',
		gqlQuery: GET_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.volleyball}`],
		eventTypeKey: EEventTypeKey.volleyball,
		eventTypeGroup: EEventTypeGroup.eventTable,
		eventMode: EEventMode.drawGame,
		categoryIds: [EEventTypeId.volleyball],
		name: 'volleyball',
		displayName: 'Volleyball',
		langKey: 'VolleyBall',
		gqlQuery: GET_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.cricket}`],
		eventTypeKey: EEventTypeKey.cricket,
		eventTypeGroup: EEventTypeGroup.eventTable,
		eventMode: EEventMode.drawGame,
		categoryIds: [EEventTypeId.cricket],
		name: 'cricket',
		displayName: 'Cricket',
		langKey: 'CRICKET',
		gqlQuery: GET_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.horseracing}`],
		eventTypeKey: EEventTypeKey.horseRacing,
		eventTypeName: 'market_event_type@horse_racing',
		eventTypeGroup: EEventTypeGroup.matchTable,
		eventMode: EEventMode.racing,
		categoryIds: [EEventTypeId.horseracing],
		name: 'horseracing',
		displayName: 'Horse Racing',
		langKey: 'HORSE_RACING',
		gqlQuery: GET_INNER_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.greyhoundracing}`],
		eventTypeKey: EEventTypeKey.greyhoundRacing,
		eventTypeGroup: EEventTypeGroup.matchTable,
		eventMode: EEventMode.racing,
		categoryIds: [EEventTypeId.greyhoundracing],
		name: 'greyhoundracing',
		displayName: 'Greyhound Racing',
		langKey: 'GREYHOUND_RACING',
		gqlQuery: GET_INNER_EVENTS,
	},
	{
		settings: [`eventType:${EEventTypeId.SPECIAL_FANCY}`],
		eventTypeKey: 'SPECIAL_FANCY',
		gqlQuery: GET_INNER_EVENTS,
	},
	// {
	// 	settings: [`eventType:${EEventTypeId.election}`],
	// 	categoryIds: [EEventTypeId.election],
	// 	name: 'The Hundred',
	// 	displayName: 'The Hundred',
	// 	langKey: 'SPECIALEVENT',
	// },
	{
		settings: [`eventType:${EEventTypeId.election}`],
		categoryIds: [EEventTypeId.election],
		name: 'SPECIALEVENT ',
		displayName: 'Special Market',
		langKey: 'SPECIALEVENT',
	},
	{
		settings: [`eventType:${EEventTypeId.bookmaker}`],
		eventTypeKey: 'SPECIAL_FANCY',
		categoryIds: [EEventTypeId.SPECIAL_FANCY],
		name: 'bookmaker_sporttab',
		displayName: 'Bookmaker',
		langKey: 'BOOKMAKER',
	},
	{
		settings: [`eventType:${EEventTypeId.politics}`],
		categoryIds: [EEventTypeId.politics],
		name: 'election',
		displayName: 'politics',
		langKey: 'POLITICS',
	},

];

const configs: any = {};
export default (eventTypeId: number) => {
	let config: typeof masterConfig = configs[eventTypeId];
	if (!config) {
		const ycbObj = new YCB.Ycb(configArray);
		config = ycbObj.read({ eventType: eventTypeId });
		configs[eventTypeId] = config;
	}
	return config;
};
