import React from 'react'
import { BodyWrapper, SubBodyWrapper } from "src/components/udb/commonStyle";
import styled from 'styled-components'
// import DoneIcon from "src/components/icon/DoneIcon";
import { SvgIcon } from "src/components/udb/commonStyle";
import config from 'src/config/config';
import Intl from '../common/Intl';

type Props = {
    message: string;
    amount: string;
    desc?: string | React.ReactDOM
    status: string,
    closeHandler: () => void
}

const DepositMessage = ({ message, amount, desc, status, closeHandler }: Props) => {
    const classN = status && status === 'FAILURE' ? 'fail' : ''
    let errGIF = status && status === 'FAILURE' ? <img src={`${config.imageV3NonOptHost}/static/media/payment-failed.gif`} alt="payment-failed" /> :<img src={`${config.imageV3NonOptHost}/static/media/deposit_completed.gif`} alt="deposit_completed" />
    return (
        <BodyContainerForDeposit id={status === "FAILURE" ? "depositfailure" : "depositsuccess"} >
            <SubBodyContainerForDeposit>
                <Textinside>
                    <SvgWrapper className={classN}>
                        {errGIF}
                    </SvgWrapper>
                    <p>{message}</p>
                    <p className='pTag'>&#x20b9; {amount}</p>
                    {desc && <p>{desc}</p>}
                    <MsgBox>
                        <strong className="setunderline"><Intl langKey="DEPOSIT@Attention" />:</strong> <Intl langKey="DEPOSIT@Attentionnote" />
                    </MsgBox>
                    <MsgBox><Intl langKey="DEPOSIT@BONUSNOTE" /></MsgBox>
                </Textinside>
                <CloseWrapper onClick={closeHandler}>X</CloseWrapper>
            </SubBodyContainerForDeposit>

        </BodyContainerForDeposit>
    )
}

export default DepositMessage;

const MsgBox = styled.p`
width:100%;

.setunderline {
    animation: mymove 1s infinite;
}

@keyframes mymove {
    from {text-decoration: none; color:#B0BFD6}
    to {text-decoration: underline;color:red}
  }

`
const BodyContainerForDeposit = styled(BodyWrapper)`
width: 100%;
height: 100%;
justify-content: center;
padding: 2em 1em;
position:relative;

`
const SubBodyContainerForDeposit = styled(SubBodyWrapper)`
width: 95%;
height: 371px;
left: 296px;
justify-content: center;

`

const Textinside = styled.div`
color:white;
 display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-weight: "200";
font-size: 14px;
width:100%;
margin-top: 5vh;
    & p {
        margin: 3px 0px;
        color: #B0BFD6;
    }
    .pTag{
        font-size: 24px;
        font-weight: bolder;    
    }
`

const SvgWrapper = styled(SvgIcon)`
img {
    width: 200px;
    height: 200px;
}
svg {
    width: 27px;
    height: 27px;
  }
  &.fail {
    circle {
        fill:red
    }
  }
  
  `;

const CloseWrapper = styled.span`
  position: absolute;
  top: 41px;
  color: #fff;
  right: 55px;
  font-family: serif;
  font-size: 13px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  padding: 1px 4px;
  cursor: pointer;
  
  `
