import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import SpecialFancyConfig from "../../../components/eu-content/SpecialFancyConfig";
import Intl from "../../common/Intl";
import config from "../../../config/config";
import media from "../../../util/mediaUtil";
// import routerUtil from "../../../util/routerUtil";
import ExchangeTopGames from "../exchange-page/exchangepage-topgames/index";
// import { motion } from "framer-motion";
// import HomeExchange from "src/components/home-exchange";
// import EditDefaultStake from "../../../components/eu-content/order/EditDefaultStake";
// import BetSlip_myBet_tab from "src/components/BetSlip/betslip-mybet_tabs";
// import UpElection from "src/images/upsubmenu.png";
// import Asiacup from "src/images/asiaCupnew.png";
// import WorldCupT20 from "src/images/worldCupT20.png";
// import Gjdropdown from "src/images/gjdropdwn.png"
// import Hundred from "src/images/hundred.png"
// import PunjabElection from "src/images/Pnjsubmenu.png";
import { Link } from "react-router-dom";
// import { withOrderListContext } from "src/store/OrderListContext";
import { useParams, useLocation } from "react-router-dom";
// import Bg from "../../../images/iMAGE1_iexch.png";
// import BgDark from "../../../images/iMAGE1_iexch.jpg";
// import Switch from "../Switch";
// import { getGTMId } from "src/util/gtmUtil";
// import { ECookieName } from "src/util/utilModel";
// import cookieUtil from "src/util/cookieUtil";
import { withAppContext } from "src/store/initAppContext";
import { Helmet } from "react-helmet";
import EventNotFound from "../EventNotFound";
import SEOContents from "src/components/SEOContents";

// import MenuSidebar from "src/components/home-exchange/menu-sidebar";


// const bbdropdown = `${'/static/media/bb-yolodropdown.png'}`
// const pslCup=`${'/static/media/pslCup.png'}`
// const iplCup=`${'/static/media/iplcupSPL.png'}`



const MainElection = (props) => {

  const { app: {
    brandId
  }, theme } = props

  // const [isDarkTheme, setIsDarkTheme] = useState(false);
  // const [backgroundImage, setBackgroundImage] = useState(
  //   localStorage.getItem("theme") === "Dark" ? BgDark : Bg
  // );

  // useEffect(() => {
  //   const themeS =
  //     JSON.parse(localStorage.getItem("isDarkTheme")) === null
  //       ? false
  //       : JSON.parse(localStorage.getItem("isDarkTheme"));
  //   setIsDarkTheme(themeS);
  // }, []);

  // const toggleTheme = () => {
  //   let themeArray = ["Dark", "Light", "Default"];
  //   let selectedIndex = !isDarkTheme;
  //   let themedata = themeArray[selectedIndex ? 0 : 1];
  //   setIsDarkTheme(!isDarkTheme);
  //   localStorage.setItem("isDarkTheme", JSON.stringify(!isDarkTheme));
  //   cookieUtil.set(ECookieName.COOKIE_THEME, themeArray[selectedIndex ? 0 : 1]);
  //   setBackgroundImage(
  //     cookieUtil.get(ECookieName.COOKIE_THEME) === "Dark" ? BgDark : Bg
  //   );

  //   const { setState } = props.app || {};
  //   if (typeof setState === "function") {
  //     let _reqData = {
  //       theme:
  //         cookieUtil.get(ECookieName.COOKIE_THEME) === "Dark"
  //           ? "Dark"
  //           : "Light",
  //     };
  //     setState(_reqData);
  //   }
  // };

  let location = useLocation();
  let { pathname } = location;
  return (
    <Bgdiv>
      <SEOContents pageName="specialMarket" />

      <ExchangeTopGames />

      <Container >
        {/* <motion.div 
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            > */}
        <ExchangeWrapper>

          {/* <SidebarWrap >
				<MenuSidebar/>
			</SidebarWrap> */}
          <ElectionMainWrap>
            <SpecialFancyConfig>
              {(sfConfig: any[]) => {
                const election = sfConfig.find(
                  (item: any) => item.type === "ELECTION"
                );
                return (
                  <>
                 {/* ipl cup*/}

                 {/* <Link to="/market/100/-1/533742/1">
                      <ElectionMainButton
                        className={
                          pathname.includes("/market/100/-1/533742/1")
                            ? "active"
                            : null
                        }
                      >
                        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.election2024}`} alt="" />

                        <GameTitleText>
                          <Intl langKey= "TITLE@ELECTION_2024"/>
                        </GameTitleText>
                      </ElectionMainButton>
                    </Link>  */}

                    {/* <Link to="/market/100/-1/533771/1">
                      <ElectionMainButton
                        className={
                          pathname.includes("/market/100/-1/533771/1")
                            ? "active"
                            : null
                        }
                      >
                        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.IPL2024}`} alt="" />

                        <GameTitleText>
                          <Intl langKey= "TITLE@IPL_ELECTION"/>
                        </GameTitleText>
                      </ElectionMainButton>
                    </Link> */}

                  

                    <EventNotFound />
                  </>
                );
              }}
            </SpecialFancyConfig>
          </ElectionMainWrap>
        </ExchangeWrapper>
      </Container>
    </Bgdiv>
  );
  // }
};

export default withTheme(withAppContext(MainElection));

const Bgdiv = styled.div`
  background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.darkBG')});
  background-size: cover;
  background-repeat: norepeat;
  background-position: top right;
  justify-content: space-between;
  min-height: 100vh;
`
// const SidebarWrap = styled.div`
//   display: flex;
//   position: relative;
// `;

const Container: any = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  /* height:100vh; */

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-size: cover;
  background-repeat: no repeat;
  background-position: center;
  // height: 100vh;
  justify-content: space-between;
  min-height: 100vh;
  margin-top:10px;
`;
const ExchangeWrapper = styled.div`
	width: 100%;
	display: flex;
	height: 75vh;
`;

// const SidebarWrapper = styled.div`
//   // position: relative;
// `;

// const BetSlipWrapper = styled.div`
//   // width: 25%;
//   // width: 300px;
//   // padding-top:35px;
//   width: 25%;
//   position: sticky;
//   top: 6em;
//   overflow-y: scroll;
//   height: calc(100vh - 6em);
//   right: 0;
//   overflow: hidden auto;
//   scroll-behavior: smooth;
//   overflow-y: overlay;
//   // flex-shrink: 0;

//   // &:focus {
//   // 	outline: 0;
//   // }
// `;

const ElectionMainWrap = styled.div`
  width: 100%;
  display: flex;
  /* margin: 50px 0 0; */
  justify-content: space-evenly;
  ${media.md`
	display: block;
	margin: 5px 0 0;
	`};

  a {
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  > img {
    display: block;
    margin: 0 auto 15px;
    transition: all 0.3s ease-in-out;
    max-width: 80%;
  }
  &:hover {
    > img {
      transform: scale(1.1);
      filter: drop-shadow(0 0 20px rgba(228, 42, 37, 0.1));
    }
    > span {
      color: ${theme(`palette.primary-background-shift`)};
    }
    > span:after {
      width: 80px;
    }
  }

  // width: 100%;
  // padding: 35px 20px 20px 0px;
`;

const ElectionMainButton = styled.div`
  cursor: pointer;
  position: relative;
  // width: 33.33%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  
  > img {
    display: block;
    margin: 0 auto 15px;
    transition: all 0.3s ease-in-out;
    max-width: 75%;
  }
  &:hover {
    > img {
      transform: scale(1.1);
      filter: drop-shadow(0 0 20px rgba(228, 42, 37, 0.1));
    }
    > span {
      // color: ${theme(`palette.primary-background-shift`)};
    }
    > span:after {
      width: 80px;
    }
  }

  ${media.lg`
	font-size: 16px;
	`} 
  
  ${media.md`
		width: 100%;
		font-size: 20px;
		margin: 0 0 5px;
		border-radius: 5px;
		overflow: hidden;
		> img{
			max-width: 100%;
			margin: 0 auto;
			border-radius: 5px;
		}
	`};
`;
// const LangText = styled.span`
//   position: relative;
//   transition: all 0.3s ease-in-out;
//   &::after {
//     content: "";
//     position: absolute;
//     width: 0;
//     height: 1px;
//     background: ${theme(`palette.primary-background-shift`)};
//     opacity: 0.8;
//     bottom: -10px;
//     left: 50%;
//     transform: translateX(-50%);
//     transition: all 0.3s ease-in-out;
//     ${media.lg`
// 			display: none;
// 			`};
//   }
//   ${media.md`
// 	position: absolute;
// 	z-index: 2;
// 	width: 100%;
// 	bottom: 10px;
// 	left: 0;
// 	color: ${theme(`palette.text-light`)};
// 	`};
// `;

const GameTitleText = styled.span`
  position: relative;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-radius: 11px;
  background: ${theme(`palette.topGamesSectionGamesBgHover`)};
  color: ${theme(`palette.gameTitleTextColor`)};
  

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    background: ${theme(`palette.game-titleprimary-background-shift`)};
    opacity: 0.8;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    ${media.lg`
			display: none;
			`};
  }
  ${media.md`
    position: absolute;
    z-index: 2;
    width: 100%;
    bottom: 10px;
    left: 0;
    color: ${theme(`palette.game-title-text-light`)};
	`};
`;

// const ToggleButton = styled.div`
//   color: white;
//   width: 25%;
//   height: fit-content;
//   font-size: 15px;
//   font-weight: 600;
//   margin-top: 5px;
//   /* width: 250px; */
//   display: flex;
//   flex-direction: row;
//   justify-content: space-evenly;
//   /* position: relative;
//     left: 100px;
//     top: -50px; */
//   position: absolute;
//   top: 10em;
//   right: 0;
//   padding: 10px;
//   background: ${theme("palette.secondary-background-color-new")};

//   .switchdiv {
//     /* padding-left: 10px;
//         padding-right: 10px; */
//     padding-left: 0px;
//     padding-right: 10px;
//     border-radius: 99em;
//     border: 1px solid #ffff;
//     width: 46px;
//   }
// `;
