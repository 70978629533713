import { useEffect } from "react";
import FooterContainer from "src/components/footer/footer";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import Accordion from '../../components/Accordion/Accordion';
import { myProfileData, bonusData, DepositData, ProductsData, affiliateDate, securityData } from "./faqData";
import { myProfileDataBD, bonusDataBD, DepositDataBD, ProductsDataBD, affiliateDateBD, securityDataBD,securityDataBazziadda ,bonusDataBAdda} from "./faqdatabetdaily";
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { bonusData39, securityData39 } from "./FAQfomo";
import FAQ39 from "./FAQ39";


const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/faq/`,
                    "name": "FAQ's",
                    "position": 2
                },
              ],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

 function FAQ(props: any) {

    const {app: {
        brandId
    }, theme} = props

    let WebsiteName = ''
    let WebsiteLink = ''
    switch(props.app.brandId){
        case 31:
            WebsiteName = 'YOLO247';
            WebsiteLink= 'www.yolo247.net'
            break; 
            case 32:
                WebsiteName = 'YOLO247';
                WebsiteLink= 'www.a-yolo247.com'
            break;  
                case 33:
                    WebsiteName = 'BAAZI ADDA';
                    WebsiteLink= 'www.baaziadda.com'
                    break; 
                    case 34:
                        WebsiteName = 'BETDAILY';
                        WebsiteLink= 'www.betdaily.club'
                        break;   
                        case 39:
                        WebsiteName = 'FOMO7';
                        WebsiteLink= 'www.fomo7.com'
                        break;   
    } 

    let domainURL = 'https://www.yolo247.net';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
      }

    const { config: { showAffiliate } } = theme;

    useEffect(() => {
        window.scrollTo(0,0)
    })

    return (
        <>
        {(brandId === 39 || brandId === 40) ? <FAQ39 /> : (
            <>
                   <Helmet>
            <script type="application/ld+json">
                {
                    structuredData(domainURL)
                }
            </script>
        </Helmet>
        <ContentWrapper>
        <SEOContents pageName="faq"/>
        <AccordionHead>My Account</AccordionHead>
                {brandId == 33 || brandId == 34 ?
                    (myProfileDataBD(brandId) || []).map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    )) :
                    (myProfileData(brandId) || []).map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                <AccordionHead>Bonus Program</AccordionHead>
                
                {brandId == 33 ? bonusDataBAdda.map((elm)=>(
                    <Accordion
                    key={elm.id}
                    title={elm?.question}
                    content={() => ({ __html: elm?.answer })}
                    lists={elm?.lists}
                />
                )):
                brandId == 34 ?
                    (bonusDataBD.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        />
                    ))) :
                    (brandId == 39 || brandId == 40) ?
                    (bonusData39.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        />
                    ))) :
                    (bonusData.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        />
                    )))}
                <AccordionHead>Deposit & Withdrawal</AccordionHead>
                

                {brandId == 33 || brandId == 34 ? (DepositDataBD(brandId) || []).map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    />
                )) : (DepositData(brandId) || []).map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    />
                ))}
                <AccordionHead>Products</AccordionHead>
                


                {brandId ==33 || brandId ==34 ? ( ProductsDataBD.map((faq) => (
                <Accordion
                key={faq.id}
                title={faq?.question}
                content={()=>({__html:faq?.answer})}
                lists={faq?.lists}
                />
            ))):( ProductsData.map((faq) => (
                <Accordion
                key={faq.id}
                title={faq?.question}
                content={()=>({__html:faq?.answer})}
                lists={faq?.lists}
                />
            )))}
            {showAffiliate && (<><AccordionHead>Affiliate</AccordionHead>
                    {brandId == 33 || brandId == 34 ? (affiliateDateBD.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        />
                    ))) :(affiliateDate.map((faq) => (
                            <Accordion
                                key={faq.id}
                                title={faq?.question}
                                content={() => ({ __html: faq?.answer })}
                                lists={faq?.lists}
                            />
                        )))}</>)}
            <AccordionHead>Security</AccordionHead>
                {brandId == 33 ? securityDataBazziadda.map((elm)=>(
                    <Accordion
                        key={elm.id}
                        title={elm?.question}
                        content={() => ({ __html: elm?.answer })}
                        lists={elm?.lists}
                    />
                )):

                 brandId == 34 ? (securityDataBD.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    />
                ))) :
                (brandId == 39 || brandId == 40) ? (securityData39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    />
                ))): (securityData.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    />
                )))} 
          </ContentWrapper></>
        )}
          <FooterContainer/>
        </>
      );


     
        }
    export default withTheme(withAppContext(FAQ));



    const AccordionHead = styled.h1`
        font-size: 15px;
        color: #f4a322;
        margin-top:10px;
        margin: 1px 0px;
        padding: 5px 15px;
        background-color: #0000008a;
        `;
    const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 1em 1.25em;

    h1{
        color: #EEBC15;
        
    }

    p,
	h3,
	h4,
	h5,
	h6 {
		color: #fff;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
        color: #fff;
	}

	a {
		color: #EEBC15;
		text-decoration: underline;
	}

    span{
        font-weight: 700;
    }
`;
