import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import Footer from 'src/components/footer/footer';

function FomoWorldCupdailyDespositBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 39 || brandId == 40) ? "FOMO7" : 'Baazi Adda';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <h6>This August, embark on the ultimate adventure: Boost your thrill with daily deposits, seize a turbocharged weekly double cashback up to 10%, and elevate the excitement with {domain}!</h6>
                    <h6>As gaming fever sweeps the nation with the Independence day, {domain} has a sensational offer that'll leave you stunned. Get ready to win big and boost your winnings with an astounding cashback,  with our unbeatable Daily Deposit Bonus. You can bag up to an astonishing 10% cashback every week!</h6>
                </TitleText>
                <Divider></Divider>
                <LoyaltyWrapper>
                    <ReferralText>
                        <h1>Gear Up For A Gaming Adventure with</h1>
                        <h1>10% Double Cashback</h1>
                    </ReferralText>
                    <UnderLine></UnderLine>
                    <JoiningWrapper>
                        <BonusContainer>
                            <BonusContent>
                                <h3>Step 1</h3>
                                <h1>Deposit</h1>
                                <p>Just like a pro, be consistent and make a deposit each day of the week, from Monday to Sunday. Consistency is the key!</p>
                            </BonusContent>
                            <BonusContent>
                                <h3>Step 2</h3>
                                <h1>Deposit Daily</h1>
                                <p>Just as a pro maintain their form throughout a tournament, keep up your deposit streak for all seven days. This is your ticket to unlocking the fantastic additional cashback bonus.</p>
                            </BonusContent>
                            <BonusContent>
                                <h3>Step 3</h3>
                                <h1>Play Like a Winner</h1>
                                <p>once you successfully deposit every day of the week, you become eligible for the additional cashback bonus.</p>
                            </BonusContent>
                        </BonusContainer>
                    </JoiningWrapper>
                </LoyaltyWrapper>
                <YellowDivider></YellowDivider>
                <JoiningWrapper className='yellowBg'>
                    <JoiningContainer>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoReffImgTwo}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h1>Weekly Cashback</h1>
                            <p>At the end of each week, you can turn your losses into wins with our sensational cashback bonus. You can avail up to an incredible 10% cashback bonus on your net losses incurred throughout the week.</p>
                        </JoiningContent>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoReffImgOne}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h1>Monday Blues</h1>
                            <p>Beat the Monday blues with extra bonus – the cashback bonus amount will be  credited to your account every Monday.</p>
                        </JoiningContent>
                        <LazyLoadImage
                            className="PlayerImg"
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWorldCupdailyDepositPlayerVk}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </JoiningContainer>
                </JoiningWrapper>
                <Divider></Divider>
                <LoyaltyWrapper>
                    <ReferralText>
                        <h1>Daily Deposit Bonus for</h1>
                        <h1>August 2024</h1>
                    </ReferralText>
                    <LazyLoadImage
                        className="PlayerImg"
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWorldCupdailyDepositTable}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </LoyaltyWrapper>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h1>Terms & Conditions</h1>
                    </TermsandconditionsHeading>
                    <UnderLine></UnderLine>
                    <TermsandconditionsBody>
                        <ol>
                            <li>This promotion is valid for all {domain} members.</li>
                            <li>This bonus amount will be capped at INR 7,000</li>
                            <li>Bonus amount will be added to your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun). Valid from 2nd August to 29th August.</li>
                            <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.</li>
                            <li>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</li>
                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</li>
                            <li>In the event, any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ol>
                    </TermsandconditionsBody>
                </Termsandconditions>
            </InformationWrapper>
            <Footer />
        </>
    )
}



export default withTheme(withAppContext(FomoWorldCupdailyDespositBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWorldCupdailyDepositBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yellowBg {
        width: 100%;
        background: radial-gradient(37.24% 47.13% at 50% 0%, #292000 0%, rgba(1, 4, 6, 0.00) 71%);
        /* backdrop-filter: blur(6.849999904632568px); */
        padding: 5rem 0 1rem 0;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const YellowDivider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#C19B11 0%, #00000000 50%);
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;
    gap: 1.5rem;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
        width: 70%;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    /* backdrop-filter: blur(6.849999904632568px); */
    padding: 3rem 3rem 5rem 3rem;
    margin: 0 -3rem;
    text-align: center;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    img {
        width: 65%;
        padding-top: 2rem;
    }
`;


const ReferralText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    h1 {
        margin: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 -3rem;
    position: relative;


    .referraltext {
        padding-top: 3rem;
    }
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;
    padding: 2rem 0 2rem 6rem;
    align-items: stretch;
    position: relative;

    .PlayerImg {
        position: absolute;
        width: 35rem;
        bottom: -4%;
        right: -6%;
    }
`;

const BonusContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 2rem;
    align-items: stretch;
    position: relative;
    width: 92%;
`;

const JoiningContent = styled.div`
    padding: 2rem 3rem;
    width: 28.5%;
    border-radius: 20px;
    background: radial-gradient(71.58% 74.35% at 50.18% -11.77%, #112333 0%, #010406 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    ::after {
        position: absolute;
        top: -1px; bottom: 0px;
        left: -1px; right: -1px;
        background: linear-gradient(180deg, #666, transparent);
        content: '';
        z-index: -1;
        border-radius: 20px;
    }

    h1 {
        background: linear-gradient(180deg, #FED546 18.06%, #FEA533 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        font-style: italic;
        line-height: normal;
        text-transform: uppercase;
    }

    p {
        margin: 0;
        color: #FFF;
        text-align: justify;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    span {
        color: #FEBA3B;
    }

    img {
        width: 50%;
    }
`;

const BonusContent = styled(JoiningContent)`
    width: 28%;
    align-items: flex-start;

    h3 {
        margin: 0;
        font-size: 18.696px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-style: italic;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFF 18.06%, #CFCFCF 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-right: 5px;
    }

    h1 {
        padding-right: 0.5rem;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 60%;
    height: auto;
    margin: 1rem 20% 3rem 20%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWorldCupdailyDepositTandCAfter")});
        width: 249px;
        height: 258px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -12.4rem;
        bottom: -7rem;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.5px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;