import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/App.css';
import './fonts/fonts.scss';
import config from './config/config'

const HandleOfflineFun = ({ flag = false }: { flag?: boolean }) => {

  const showMSG = (msgFlag) => {
    if (msgFlag) {
      let testDoamin =  window.location.hostname
      fetch(`${config.offlineAPI}/Prod/message`,{
        method:'POST',
        body:JSON.stringify({
          "domain":testDoamin
        })
      })
      .then(res => res && res.json())
      .then(res => {
          const {redirect, redirecturl} = res.data || {}
          console.log('AAAA-',redirect,redirecturl);
          if (redirect && redirecturl) {
            window.location.href = redirecturl;
          }
        })
        .catch(err => console.log('Network Issue-2', err))
    }
    else {
      console.error('Network Issue')
    }
  }

  //this condition will also fine
  if (flag && navigator.onLine) {
    showMSG(true)
  }
  else if (flag && !navigator.onLine) {
    showMSG(false)
  }

  return false;
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);


//Adding Service worker for offline cache

serviceWorkerRegistration.register({
  handleOffline: HandleOfflineFun
});

//<React.StrictMode></React.StrictMode> // removed for some time
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
