import React, { PureComponent, useState, useEffect, Fragment } from "react";
import { ifProp, theme, withProp } from "styled-tools";
import isEmpty from "lodash/isEmpty";
import EmptyTable from "src/components/common/table/feature/EmptyTable";
import styled from "styled-components";
import {
  IOrderListContext,
  IOrderTicket,
  withOrderListContext,
} from "../../../../store/OrderListContext";
import {
  IMatchListContext,
  withMatchListContext,
} from "../../../../store/MatchListContext";
import { calExposure } from "./exposureCalculate";
import media from "../../../../util/mediaUtil";
import Intl from "../../../common/Intl";
// import InPlayLiveIcon from '../../../images/inplay_live.png';
// import InPlayCricketBall from '../../../images/inplay_cricket_ball_icon.svg';
import InPlayPlayIcon from "../../../../images/play_icon.png";
import Cricket from "../../../../images/cricketicon.png";
import Tennis from "../../../../images/tennisicon.png";
import Soccer from "../../../../images/footballicon.png";
import EventLiveTvIcon from "src/images/event_table_live_tv.png";
import Icon from "../../../common/PNGIcon";
import { color } from "../../../../sass-files/variable";
import {
  ColMask,
  TableBodyRow as EventTableBodyRow,
} from "../../../common/table/baseTableStyles";
import { Link } from "react-router-dom";
import util from "../../../../util/util";
import {
  ECookieName,
  EEventTypeId,
  EMarketSource,
} from "../../../../util/utilModel";
import FavoriteButton from "../../FavoriteButton";
import moment from "moment";
import {
  OddsContentCell,
  OddsGroupWrapper,
  OddsTitleCell,
  StateContentCell,
  StateTitleCell,
  TeamContentCell,
  TeamName,
  TeamNumber,
  TeamsWrapper,
  TeamTitleCell,
  TeamTitleWrapper,
  TeamProviderWrapper,
  TeamProviderContent,
  TeamProviderWrap,
  MoreButton,
  ManyMoreOdds,
} from "../../MarketList";
import { useNavigate } from "react-router-dom";
//imported Components
import UseBookmaker from "../../../home-exchange/in-play-desktop/use-bookmaker-content";
import Odd from "./Odd";
import OddsWrapper from "./Odd";
import { useLocation } from "react-router-dom";
import cookieUtil from "src/util/cookieUtil";
import config from "src/config/config";
import { CRMLiveTVClick } from "src/util/crmUtil";

const { tabsignup } = color;
const { secondarycolor } = color;
const { exchangeTableHeadBg } = color;
const isLoggedIn = cookieUtil.get(ECookieName.COOKIE_TOKEN);
interface EventTableProps {
  dataList: any;
  isFavorite?: boolean;
  liveTvData?: any;
  app?: any;
  matchListContext?: any;
  homePage?: boolean;
}
interface MarketRow {
  key: any;
  event: any;
  market: any;
  isCompetitionOrder: boolean;
  isEventOrder: any;
  handleInnerPageRoute?: (market: any, event: any, tvData: any) => void;
  liveTvData: any;
  app?: any;
  orderList?: any;
  boostMarket?: any;
  overUnderMarket?: any;
  homePage?: boolean;
}

const VolleyBall = `${'/static/media/volleyballsmallicon.png'}`

function EventTable(props: EventTableProps) {
  let navigate = useNavigate();
  const RouterPush = (market, event, tvData) => {
    let selectedMatch: any = "1";
    const { categoryId, competitionId, eventId } = event;
    selectedMatch = tvData.find((each) => each.EventId === event.eventId);
    if (!selectedMatch) {
      selectedMatch = "1";
    } else {
      selectedMatch = selectedMatch.MatchID;
    }
    navigate(
      `${
        "/market/" +
        categoryId +
        "/" +
        competitionId +
        "/" +
        eventId +
        "/" +
        selectedMatch
      }`,
      { state: {} }
    );
  };
  let location = useLocation();
  const { matched } = props.matchListContext;
  let storageLiveTvData = JSON.parse(localStorage.getItem("liveTv"));
  useEffect(() => {
    // setLiveTvData(storageLiveTvData)
  }, [props.dataList]);
  // if (!Array.isArray(props.dataList) || props.dataList.length === 0) { return <EmptyTable /> }
  return (
    <InPlayWrapper>
      <TittleWrapper>
        <ul className="table_tittle">
          <li className="match">
            <span className="matchtittle">
              <Intl langKey="TABLE@MATCH" />
            </span>
            <span className="status">
              <Intl langKey="TABLE@STATUS" />
            </span>
          </li>
          {/* <li className="status"></li> */}
          <li className="clickodd">
            <Intl langKey="TABLE@CLICK_ON_ODDS_TO_BET" />
          </li>
          {/* <li className="option"><Intl langKey="TABLE@OPTIONS" /></li> */}
          <li className="parallax">
            <span>
              <Intl langKey="TABLE@BOOST_YOUR_BET" />
            </span>
            <p>
              <Intl langKey="TABLE@USE_BOOKMAKER_AND_FANCY" />
            </p>
          </li>
        </ul>
      </TittleWrapper>
      <InPlayTable>
        <TableHead>
          <TeamsInplayWrap>
            <TeamHead>
              <Intl langKey="TABLE@TEAMS" />
            </TeamHead>
            <InPlayHead>
              <span className="live_icon"></span>
              <text></text>
            </InPlayHead>
          </TeamsInplayWrap>

          <OddsHead>
            <span>1</span>
            <span>X</span>
            <span>2</span>
          </OddsHead>
          <ManyMoreHead></ManyMoreHead>
          <FancyHead>
            {/* <span>1</span>
                        <span>X</span>
                        <span>2</span> */}
          </FancyHead>
          <More1X2>
            {/* <span>1</span>
                        <span>X</span>
                        <span>2</span> */}
          </More1X2>
        </TableHead>
      </InPlayTable>
      {getEventTableContent(
        props.dataList,
        RouterPush,
        storageLiveTvData,
        props.app,
        matched,
        props.homePage
      )}
    </InPlayWrapper>
  );
}

export default withMatchListContext(
  withOrderListContext(EventTable, ["betArray"]),
  [
    "matched",
    "unmatched",
    "updatedUnmatchedSlips",
    "openedMobileUnmatchedSlipOrderNumber",
  ]
);

const getEventTableContent = (
  list: any,
  innerPageRoute: (market: any, event: any, tvData: any) => void,
  isLiveTv: any,
  app: any,
  orderList: any,
  homePage: boolean
) => {
  return list?.map((event, i: number): JSX.Element => {
    const otherMarket = [5, 6, 7, 8, 9, 10, 11, 12, 13];
    const market =
      (event.snk || []).find(
        (item) => item.marketType === 1 || item.marketType === 16
      ) || {};
    const boostMarket =
      (event.snk || []).find((item) => item.marketType === 2) || {};
    const overUnderData =
      (event.snk || []).find((item) => otherMarket.includes(item.marketType)) ||
      {};
    const overUnderMarket =
      (event.snk || []).some((item) => otherMarket.includes(item.marketType)) ||
      false;
    return (
      <MarketRow
        homePage={homePage}
        overUnderMarket={overUnderMarket}
        boostMarket={overUnderMarket ? overUnderData : boostMarket}
        orderList={orderList}
        app={app}
        handleInnerPageRoute={innerPageRoute}
        liveTvData={isLiveTv}
        key={event.eventId || ""}
        event={event}
        market={market || {}}
        isCompetitionOrder={event.isCompetitionOrder}
        isEventOrder={event.isEventOrder}
      />
    );
  });
};

const MarketRow = (props: MarketRow) => {
  const {
    event,
    market,
    isCompetitionOrder,
    isEventOrder,
    handleInnerPageRoute,
    liveTvData,
    app,
    orderList,
    boostMarket,
    overUnderMarket,
    homePage,
  } = props;
  const { marketStartTime, score, isInplay, timeElapsed } = event;
  const { categoryId, home: homeScoreInfo, away: awayScoreInfo } = score || {};
  const { isOrder } = market as any;
  const currentConfig = 0;
  const marketId =
    (market && (market as any).marketId) || util.getValue(market, "list[0].id");
  const marketStatue =
    (market && (market as any).status) ||
    util.getValue(market, "list[0].status");
  // Code Changed - Manoj ( commented the code below since we no longer using isBookmaker)
  // const isShowOdds = isBookmaker ? true : false;
  const isMask =
    !isOrder ||
    (categoryId !== EEventTypeId.fancybet &&
      (isCompetitionOrder === false || isEventOrder === false)) ||
    marketStatue === "SUSPENDED";
  const OddsContent = [
    getOddsGroup(
      event,
      market,
      util.getValue(market, ["list", "0"]),
      isMask,
      homePage
    ),
    getOddsGroup(
      event,
      market,
      util.getValue(market, ["list", "2"]),
      isMask,
      homePage
    ),
    getOddsGroup(
      event,
      market,
      util.getValue(market, ["list", "1"]),
      isMask,
      homePage
    ),
  ];
  const isBoostMask =
    //   !isOrder ||
    categoryId !== EEventTypeId.fancybet &&
    (isCompetitionOrder === false || isEventOrder === false) &&
    marketStatue === "SUSPENDED";

  let BoostOddsContent = [];

  if (overUnderMarket) {
    BoostOddsContent = [
      getBoostOddsGroup(
        event,
        boostMarket,
        util.getValue(boostMarket, ["list", "0"]),
        !!isBoostMask,
        overUnderMarket,
        homePage
      ),
      // getOddsGroup(event, boostMarket, util.getValue(boostMarket, ['list', '2']), isMask),
      // getBoostOddsGroup(event, boostMarket, util.getValue(boostMarket, ['list', '1']), isBoostMask, overUnderMarket),
    ];
  } else {
    BoostOddsContent = [
      getBoostOddsGroup(
        event,
        boostMarket,
        util.getValue(boostMarket, ["list", "0"]),
        isBoostMask,
        overUnderMarket,
        homePage
      ),
    ];
  }
  const { resourceFrom = "" } = market;

  return (
    <TableBodyRow>
      <TableColumnDiv>
        {getTeamNameColumu({
          market,
          event,
          iconName: "cricket",
          isShowSport: false,
          handleInnerPageRoute,
          liveTvData,
          app,
        })}
        {/** Code Changed - Manoj (removed isLong={!isBookmaker}) below */}

        {/* <StateContentCell >
            <EventState
                categoryId={categoryId || 0}
                isInPlay={!!isInplay}
                homeScoreInfo={homeScoreInfo}
                awayScoreInfo={awayScoreInfo}
                timeElapsed={timeElapsed}
                marketStartTime={marketStartTime}
            />
        </StateContentCell> */}
        {/* BF凍結臨時處理 */}
        {/** Code Changed - Manoj ( removed isShowOdds Since Now we are enabling Exchange - added by them during times to hide exchange) */}

        <OddsContentCell>
          {isMask && (
            <ColMask>
              {marketStatue === "SUSPENDED" ? (
                <span>
                  <Intl langKey="TABLE@SUSPENDED" />
                </span>
              ) : (
                ""
              )}
            </ColMask>
          )}
          {OddsContent}
        </OddsContentCell>

        {/* 
            <ManyMoreOdds onClick={() => handleInnerPageRoute(market, event)} >
                <Link to="">many more odds</Link>
                <Link to="">score & stats</Link>
            </ManyMoreOdds> */}

        <OddsContentCellMore>
          {isBoostMask && (
            <ColMask>
              {marketStatue === "SUSPENDED" && (
                <span>
                  <Intl langKey="TABLE@SUSPENDED" />
                </span>
              )}
            </ColMask>
          )}
          {BoostOddsContent}
        </OddsContentCellMore>

        <TeamProviderWrapper
          onClick={() => handleInnerPageRoute(market, event, liveTvData)}
        >
          <MoreButton>More +</MoreButton>
          {getProviderColumn(event)}
          {getResourceName(event)}
          {marketStatue !== "SUSPENDED" && resourceFrom === "DRINS" && (
            <TeamProviderContentDrins key={"DRINS-TEXT"}>
              {"PRE-PLAY"}
            </TeamProviderContentDrins>
          )}
        </TeamProviderWrapper>
      </TableColumnDiv>

      {/* <BodyBookmaker> */}

      {/* <OddsContentCell>
                {isBoostMask && (
                    <ColMask>
                        {marketStatue === 'SUSPENDED' && <span>Suspended</span>}
                    </ColMask>
                )}
                {BoostOddsContent}
            </OddsContentCell> */}
      {/* </BodyBookmaker> */}

      {/* <UseBookmaker /> */}

      {/* 行動裝置寬度時才可以直接在賠率下方下注 */}
      {/* {isMobileWidth && <MobileOrderSlip selfMarketId={marketId} />} */}
      {marketStatue !== "SUSPENDED" && resourceFrom === "DRINS" && (
        <DrinsNote>
          <DrinsNoteWrapper>
            {" "}
            <Intl langKey="PrePlayNote" />
          </DrinsNoteWrapper>
        </DrinsNote>
      )}
    </TableBodyRow>
  );
};

const getProviderColumn = (event) => {
  const provider =
    event.snk.length !== 0 &&
    event.snk
      .filter((type) => type.resourceFrom)
      .map((value) =>
        value.resourceFrom === EMarketSource.BETFAIR
          ? "EX"
          : value.resourceFrom === "BOOKMAKER"
          ? "BM"
          : ""
      );
  let uniqueChars = provider ? [...new Set(provider)] : [];
  return uniqueChars.map((each: any, index: number) => {
    return <TeamProviderContent key={index}>{each}</TeamProviderContent>;
  });
};

const getResourceName = (event) => {
  const provider =
    event.snk.length !== 0 &&
    event.snk
      .filter((type) => type.resourceFrom)
      .map((value) => (value.resourceFrom === "WICKET" ? "FANCY" : ""));
  let uniqueChars = provider ? [...new Set(provider)] : [];
  return uniqueChars.map((each: any, index: number) => {
    return <ResourceNameContent key={index}>{each}</ResourceNameContent>;
  });
};

const getTeamName = (market: any) => {
  // const bookmakerNameSchema = ['list[0].selectionName', 'list[1].selectionName'];
  const exchangeNameSchema = ["list[0].name", "list[1].name"];
  return exchangeNameSchema.map((schema) => util.getValue(market, schema));
};

const openLiveTv = (event, data) => {
  const { setState, brandId, memberId} = data.app || {};
  if (typeof setState === "function") {
    let _reqData = {
      showLiveTv: true,
      liveTvUrl: event.MatchID,
    };
    setState(_reqData);
  }

  //for CRM
  CRMLiveTVClick(brandId, memberId, event.MatchID)
};

function getTeamNameColumu({
  market,
  event,
  iconName,
  isShowSport,
  handleInnerPageRoute,
  liveTvData,
  app,
}: {
  handleInnerPageRoute?: (market: any, event: any, tvData: any) => void;
  market: any;
  event: any;
  iconName: string;
  isShowSport: boolean | undefined;
  liveTvData: any;
  app: any;
}) {
  // Code Changed - Manoj ( commented isBookmaker )
  // const [homeName, awayName] = getTeamName(market, isBookmaker);  // onClick={()=>handleInnerPageRoute(market, event)}
  const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
  const [homeName, awayName] = getTeamName(market);
  const { token } = app.app;

  // console.log("mmm",awayName)
  const content =
    homeName && awayName ? (
      <>
        <TeamNameWrapper
          onClick={() => handleInnerPageRoute(market, event, liveTvData)}
        >
          <TeamTitleWrapper>
            <TeamNumber>1</TeamNumber>
            <TeamName>{homeName}</TeamName>
          </TeamTitleWrapper>
          <TeamTitleWrapper>
            <TeamNumber>2</TeamNumber>
            <TeamName>{awayName}</TeamName>
          </TeamTitleWrapper>
        </TeamNameWrapper>

        {app.app.token &&
          liveTvData && liveTvData.length > 0 &&
          liveTvData.map((each) => {
            if (each.EventId === event.eventId) {
              return (
                <LiveTvHomePage onClick={() => openLiveTv(each, app)}>
                  <img src={EventLiveTvIcon} alt="" />
                  <Intl langKey="HEADER@LIVETV" />
                </LiveTvHomePage>
                // <img onClick={() => openLiveTv(each, app)} src={InPlayPlayIcon}/>
              );
            }
          })}

        {/* {isAuth &&
                <a href={"https://livingstreamvdo.com" + "?aHR0cHM6Ly9iYmIuZ2FtZXM="} target="_blank" className="live-tv">
                    <LiveTvHomePage>
                        <img src={EventLiveTvIcon} />
                        Live Tv
                    </LiveTvHomePage>
                </a>
            } */}

        {!event.isInplay && (
          <BodyStatus
            onClick={() => handleInnerPageRoute(market, event, liveTvData)}
          >
            <Over>{moment(event.openDate).format("DD-MM-YY hh:mm A")}</Over>
          </BodyStatus>
        )}

        {/* <TeamProviderWrapper onClick={()=>handleInnerPageRoute(market, event, liveTvData)}>
                {getProviderColumn(event)}
                <MoreButton>More+</MoreButton>
            </TeamProviderWrapper> */}
      </>
    ) : (
      event.eventName
    );
    return (
        <TeamContentCell>

            {isShowSport && (
                <IconWrapper>
                    <Icon name={iconName} />
                </IconWrapper>
            )}
            <LeftIcon>
                <MatchIcon>
                    {(() => {
                        if (event.categoryId == 4) {
                            return (<img src={`${config.imageS3Host}${Cricket}`} alt="" />)
                        } else if (event.categoryId == 1) {
                            return (<img src={`${config.imageS3Host}${Soccer}`} alt="" />)
                        } else if (event.categoryId == 2) {
                            return (<img src={`${config.imageS3Host}${Tennis}`} alt="" />)
                        } else if (event.categoryId == 12) {
                            return (<img src={`${config.imageS3Host}${VolleyBall}`} alt="" />)
                        }
                    })()}
                </MatchIcon>
                <FavoriteButton
                    marketId={(market && (market as any).marketId) || ''}
                    isFavoriteProp={(market && (market as any).isFavorite) || false}
                    eventId={(market && event.eventId)}
                />
                {/* <PlayIcon>
                  {
                    app.app.token && liveTvData.length > 0 && liveTvData.map((each) => {
                          if(each.EventId === event.eventId) {
                              return (
                                <img onClick={() => openLiveTv(each, app)} src={InPlayPlayIcon}/>
                              )
                          }
                      })
                  }
                  
                </PlayIcon> */}
      </LeftIcon>
      <TeamsWrapper>{content}</TeamsWrapper>
    </TeamContentCell>
  );
}

const getBoostOddsGroup = (
  eventInfo: any,
  marketInfo: any,
  select: any = {},
  isMask: boolean,
  overUnderMarket: boolean,
  homePage?: boolean
) => {
  const selectionInfoList = marketInfo.list;
  if (selectionInfoList === undefined) {
    return null;
  }

  const {
    isOrder,
    maxPerOrderCredit,
    maxPerMarketExposure,
    marketName,
    status: marketStatus,
  } = marketInfo;
  const oddsGroup = selectionInfoList.map((fancyInfo, i) => {
    const {
      // Code Change - Manoj (added back and lay)
      back,
      lay,
      selectionId,
      backPrice,
      backValue,
      layPrice,
      layValue,
      isRunning,
      layStatus,
      backStatus,
      name,
    } = fancyInfo;
    return (
      <>
        {(!isOrder || isRunning || marketStatus === "SUSPENDED") && (
          <ColMask>
            {marketStatus === "SUSPENDED" ? (
              <Intl langKey="TABLE@SUSPENDED" />
            ) : // 'SUSPENDED'
            isRunning ? (
              <Intl langKey="TABLE@BALL_RUNNING" />
            ) : (
              // 'BALL RUNNING'
              ""
            )}
          </ColMask>
        )}
        <OverAllCol>
          {overUnderMarket ? (
            <OddsGroupWrapper>
              <OddsGroupInner>
                {/* <span>Back </span> */}
                <Odd
                  homePage={homePage}
                  key={`${marketInfo.marketId}_${selectionId}_${i}_YES`}
                  // Code Change - Manoj (instead of backPrice)
                  price={
                    isEmpty(fancyInfo)
                      ? 0
                      : `${util.getValue(fancyInfo, "back[0].price")}`
                  }
                  size={backValue}
                  type="BACK"
                  fancyInfo={fancyInfo}
                  marketInfo={marketInfo}
                  eventInfo={eventInfo}
                  isOrder={isOrder}
                  isBest={true}
                  sideStatus={backStatus}
                />
              </OddsGroupInner>

              <OddsGroupInner>
                {/* <span>Lay</span> */}
                <Odd
                  homePage={homePage}
                  key={`${marketInfo.marketId}_${selectionId}_${i}_NO`}
                  // Code Change - Manoj (instead of layPrice)
                  price={
                    isEmpty(fancyInfo)
                      ? 0
                      : `${util.getValue(fancyInfo, "lay[0].price")}`
                  }
                  size={layValue}
                  type="LAY"
                  fancyInfo={fancyInfo}
                  marketInfo={marketInfo}
                  eventInfo={eventInfo}
                  isOrder={isOrder}
                  isBest={true}
                  sideStatus={layStatus}
                />
              </OddsGroupInner>
            </OddsGroupWrapper>
          ) : (
            <OddsGroupWrapper>
              <OddsGroupInner>
                {/* <span>No</span> */}
                <Odd
                  homePage={homePage}
                  key={`${marketInfo.marketId}_${selectionId}_${i}_NO`}
                  // Code Change - Manoj (instead of layPrice)
                  price={
                    isEmpty(fancyInfo)
                      ? 0
                      : `${util.getValue(fancyInfo, "lay[0].price")}`
                  }
                  size={layValue}
                  type="LAY"
                  fancyInfo={fancyInfo}
                  marketInfo={marketInfo}
                  eventInfo={eventInfo}
                  isOrder={isOrder}
                  isBest={true}
                  sideStatus={layStatus}
                />
              </OddsGroupInner>

              <OddsGroupInner>
                {/* <span>Yes</span>        */}
                <Odd
                  homePage={homePage}
                  key={`${marketInfo.marketId}_${selectionId}_${i}_YES`}
                  // Code Change - Manoj (instead of backPrice)
                  price={
                    isEmpty(fancyInfo)
                      ? 0
                      : `${util.getValue(fancyInfo, "back[0].price")}`
                  }
                  size={backValue}
                  type="BACK"
                  fancyInfo={fancyInfo}
                  marketInfo={marketInfo}
                  eventInfo={eventInfo}
                  isOrder={isOrder}
                  isBest={true}
                  sideStatus={backStatus}
                />
              </OddsGroupInner>
            </OddsGroupWrapper>
          )}
          <span className="goals_color">{name}</span>
        </OverAllCol>
      </>
    );
  });
  return oddsGroup;
};

const getOddsGroup = (
  eventInfo: any,
  marketInfo: any,
  select: any = {},
  isMask: boolean,
  homePage: boolean
) => {
  const statusContent =
    !isMask && select.isRunning ? (
      <ColMask>
        <Intl langKey="TABLE@BALL_RUNNING" />
      </ColMask>
    ) : (
      ""
    );
  const mySelect = marketInfo.marketType === 16 ? {} : select;
  return (
    <OddsGroupWrapper
      style={{ position: "relative" }}
      key={`_BACK_${select.selectionId}`}
    >
      {statusContent}
      <Odd
        homePage={homePage}
        type="BACK"
        key={`${marketInfo.marketId}_BACK_${select.selectionId}`}
        price={
          isEmpty(mySelect) ? 0 : `${util.getValue(mySelect, "back[0].price")}`
        }
        size={
          isEmpty(mySelect) ? 0 : `${util.getValue(mySelect, "back[0].size")}`
        }
        selectionInfo={select}
        marketInfo={marketInfo}
        eventInfo={eventInfo}
        isBest={true}
        app={{ exchangeRate: 1, bfInfo: [] }}
      />
      <Odd
        homePage={homePage}
        type="LAY"
        key={`${marketInfo.marketId}_LAY_${select.selectionId}`}
        price={
          isEmpty(mySelect) ? 0 : `${util.getValue(mySelect, "lay[0].price")}`
        }
        size={
          isEmpty(mySelect) ? 0 : `${util.getValue(mySelect, "lay[0].size")}`
        }
        selectionInfo={select}
        marketInfo={marketInfo}
        eventInfo={eventInfo}
        isBest={true}
        app={{ exchangeRate: 1, bfInfo: [] }}
      />
    </OddsGroupWrapper>
  );
};

const More1X2 = styled.div`
  width: 35.1%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: ${theme("palette.InPlayTableHeadMore1X2Bg")};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  @media only screen and (min-width: 1600px) {
    width: 35.2%;
  }
`;

const ResourceNameContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("palette.ResourceNameColor")};
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
  padding: 2px 0px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

const LiveTvHomePage = styled.div`
  width: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${theme("palette.LiveTvHomePageColor")};
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  // position: absolute;
  left: 18%;
  top: 25%;
`;

const OddsGroupInner = styled.div`
  // width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 12px;
    color: #8e6018;
  }
`;

const OverAllCol = styled.div`
  // width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .goals_color {
    font-size: 10px;
    color: ${theme("palette.goalsColor")};
    text-transform: capitalize;
    position: relative;
    top: 3px;
    font-weight: 500;
  }
`;

const OddsContentCellMore = styled.div`
  // width: 30%;
  width: calc(100% / 3);
  display: flex;
  // width: 350px;
  padding: 0.4em 0px;
  z-index: 0;
  position: relative;
  justify-content: center;
  // padding-right: 1em;
  // padding-right: 3.5em;

  ${media.lg`
        width: 100%;
        // commented by ambu
        // padding: 1em 0.5em 0.6em;
        padding: 7px 6px 13px;
    `};
`;

const BodyBookmaker = styled.div`
  // width: 25%;
  width: 310px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // padding-left: 20px;

  :before {
    content: "";
    width: 1px;
    height: 82px;
    position: absolute;
    left: 30px;
    top: -18px;
    background-color: #ff9315;
    transform: skew(-20deg);

    @media only screen and (max-width: 1440px) {
      left: 7px;
    }
  }

  .fast_bet {
    text-transform: capitalize;
    font-size: 10px;
    color: #214076;
    letter-spacing: 0.05em;
    padding-top: 2px;

    strong {
      text-transform: uppercase;
    }
  }
`;

const OddsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const OddsPrice = styled.div`
  width: 54px;
  height: 26px;
  padding: 5px;
  background: #a7d8fd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #214076;
  cursor: pointer;
  margin: 0px 1px;
  border-radius: 2px;

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // width: 30%;
  // // flex-grow: 1;
  // // padding: 0.25em 0.5em;
  // padding: 0.12em 0.5em;
  // margin: 0px 0.15em;
  // position: relative;
  // z-index: 0;
  // text-align: center;
  // border-radius: 2px;
  // // min-height: 48px;
  // min-height: 42px;
  // background-color: #5FC5FF;

  :hover {
    box-shadow: 0px 0px 0px 3px #19a1ef inset;
    transition: 0.5s ease;
  }
`;

const BlankOddsPrice = styled.div`
  width: 54px;
  height: 26px;
  padding: 5px;
  background: rgba(206, 216, 234, 0.97);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #214076;
  margin: 0px 1px;
  border-radius: 2px;

  :hover {
    box-shadow: 0px 0px 0px 3px #a8aeb7 inset;
    transition: 0.5s ease;
  }
`;

const OddsPriceDark = styled.div`
  width: 54px;
  height: 26px;
  padding: 5px;
  background: #ffd6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #214076;
  cursor: pointer;
  font-size: 12px;
  margin: 0px 1px;
  border-radius: 2px;

  :hover {
    box-shadow: 0px 0px 0px 3px #b5586c inset;
    transition: 0.5s ease;
  }
`;
// const ManyMoreOdds = () => {

// }
const OddBlock = styled.div`
  width: 16.4%;
  display: flex;
`;
// Styled components
const IconWrapper: any = styled.div`
  background-color: transparent;
  border: 0;
  padding: 0;
  flex-shrink: 0;
  font-size: 14px;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
`;

const MatchIcon = styled.div`
  // width: 20px;
  // height: 20px;
  // background-image: url(${""});
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center;

  width: 27px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayIcon = styled.div`
  width: 16px;
  height: 16px;
  // background-image: url(${""});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

const ProviderWrapper: any = styled.div`
  display: flex;
  font-size: 11px;
  width: 100%;
  max-width: 96px;
  border-radius: 2px;
  margin: 0 0.5em;
  overflow: hidden;
  color: #b21318;
  font-weight: bold;
`;

const ProviderWrapperDiv: any = styled.div`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const TeamNameWrapper: any = styled.div`
  // width: 60%;
  width: 63%;
  display: flex;
  flex-direction: column;
  // margin-right: 20px;
`;

const BodyStatus = styled.div`
  // width: 120px;
  // width: 50%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  padding-left: 5px;
`;

const Score = styled.div`
  color: #7a818d;
  padding: 3px 0px;

  span {
    padding: 0px 5px;
  }
`;

const Over = styled.div`
  color: ${theme("palette.secondary-background-color-new")};
  padding: 3px 0px;
  text-align: center;

  span {
    padding: 0px 5px;
  }
`;

const TeamHead = styled.div`
  // width: 50%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;

  // @media only screen and (max-width: 1440px) {
  //     width: 130px;
  // }
`;

const InPlayHead = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;

  // .live_icon {
  //     width: 13px;
  //     height: 13px;
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     margin-right: 5px;
  // }

  text {
    color: #5ffbc3;
  }
`;

const OddsHead = styled.div`
  // width: 400px;
  // width: 40%;
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: space-around;

  // span  {
  //     padding: 0px 10px;
  // }
`;

const ManyMoreHead = styled.div`
  // width: 11%;
  // width: auto;
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FancyHead = styled.div`
  // width: 350px;
  // width: 310px;
  // width: auto;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // padding-left: 4em;
  // padding-right: 6em;
`;

const TeamsInplayWrap = styled.div`
  // width: 57%;
  width: calc(100% / 3);
  display: flex;
  align-items: center;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 20px;
  color: ${theme("palette.InPlayTableHeadColor")};
  font-size: 13px;
  padding-right: 0;
  background: ${theme("palette.InPlayTableHeadBg")};
  position: relative;
`;

const InPlayTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
  padding-bottom: 3px;
`;

const TittleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const InPlayWrapper = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  padding-bottom: 30px;

  .table_tittle {
    // width: calc(100% - 350px);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
    margin: 0px;
    background: ${theme("palette.secondary-background-color-shade")};
    /* background: #201c67; */
    box-shadow: 0px 0px 40px rgb(14 9 67 / 44%);
    padding-right: 0;
    border-radius: 5px;

    li {
      color: ${theme("palette.TableTittleContentColor")};
      font-size: 12px;
      list-style: none;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    .match {
      // width: 120px;
      // width: 57%;
      // width: calc(100%/2)!important;
      width: calc(100% / 3);
      justify-content: flex-start;
      padding-left: 20px;

      span {
        width: 50%;
      }

      .matchtittle {
        width: 73%;
      }

      // @media only screen and (max-width: 1440px) {
      //     width: 120px;
      // }
    }

    // .status {
    //     width: 120px;
    // }

    .clickodd {
      // width: 400px;
      // width: 40%;
      // width: calc(100%/2)!important;
      width: calc(100% / 3);
    }

    .option {
      // width: 120px;
      width: 11%;
      justify-content: flex-start;
    }

    .parallax {
      // width: 310px;
      // width: calc(100%/3);
      width: 37%;
      color: #fff;
      background: ${theme("palette.homeTable-background")};
      /* background: #6778E3; */
      padding: 7px 20px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      span {
        font-size: 13px;
        line-height: 13px;
        font-weight: bold;
        letter-spacing: 0.1em;
      }

      p {
        margin: 2px 0 0 0;
        font-size: 11px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: ${theme("palette.TableParallaxSecondaryTextColor")};
        font-weight: bold;
      }
    }

    // .parallax: after {
    //     content: "";
    //     width: 20px;
    //     height: 42px;
    //     // background: linear-gradient(180deg, #DEC59D 0%, #D7AE6D 100%);
    //     background: ${theme("palette.homeTable-background")};
    //     position: absolute;
    //     top: 0;
    //     left: -8px;
    //     transform: skew(-20deg); /* UNSKEW */
    // }
  }
`;

function handleInnerPageRoute(market: any, event: any) {
  throw new Error("Function not implemented.");
}

export const TeamProviderContentDrins: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("palette.ProviderContentTextColor")};
  font-size: 9px;
  font-weight: bold;
  line-height: 10px;
  padding: 5px 0px;
  letter-spacing: 0.1em;
`;

const DrinsNote = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  background: linear-gradient(
    360deg,
    #d7d7d7 -1.03%,
    rgba(215, 215, 215, 0) 89%
  );
  padding-left: 35px;
  // height: 10px;
`;
const DrinsNoteWrapper = styled.div`
  // width: 65.66%;
  position: relative;
  height: 28px;
  line-height: 28px;
  // :before {
  //   content: "";
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   right: 7px;
  //   top: 1px;
  //   // background-color: rgb(255,147,21);
  //   background-color: #000;
  //   // transform: skew(-20deg);
  // }
`;

const TableColumnDiv = styled.div`
  display: flex;
`;

const TableBodyRow = styled(EventTableBodyRow)`
  flex-direction: column;
  :before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    right: 35%;
    top: 1px;
    // background-color: rgb(255,147,21);
    background-color: #000;
    // transform: skew(-20deg);
  }
`;
