import { useEffect } from "react";
import PlaceholderImage from "src/components/Placeholder";
import config from "src/config/config";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FooterContainer from "src/components/footer/footer";
import YellowTickIcon from "src/components/icon/YellowTickIcon";
import { useNavigate } from "react-router-dom";

const SmartsoftSpinPromo = (props) => {
  const {
    app: { brandId },
    theme,
  } = props;

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gameImages = [
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.jetXEng}`,
      href: "/instant-games/JetX"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.plinkoXEng}`,
      href: "/instant-games/PlinkoX"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.circketXENg}`,
      href: "/instant-games/CricketX"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.burningIce40Eng}`,
      href: "/instant-games/BurningIce40"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.BurningIce10Eng}`,
      href: "/instant-games/BurningIce10"
    },
    // {
    //     src: `${config.imageV3Host}/static/mobile${theme.image.MultiHot5Eng}`,
    //     href: "/instant-games/Multi Hot 5"
    // },
    // {
    //     src: `${config.imageV3Host}/static/mobile${theme.image.SlicerXEng}`,
    //     href: "/instant-games/Slicer X"
    // },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.MultiHotWaysEng}`,
      href: "/instant-games/MultiHotWays"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.JunglesEng}`,
      href: "/instant-games/Jungles"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.HunterXEng}`,
      href: "/instant-games/HunterX"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.balloonEng}`,
      href: "/instant-games/Balloon"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.FootballXENg}`,
      href: "/instant-games/FootballX"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.cappaDociaEng}`,
      href: "/instant-games/CappaDocia"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.jetX3Eng}`,
      href: "/instant-games/JetX3"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.MagicGarden40Eng}`,
      href: "/instant-games/MagicGarden40"
    },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.GeniesBonanzaEng}`,
      href: "/instant-games/GeniesBonanza"
    },
    // {
    //     src: `${config.imageV3Host}/static/mobile${theme.image.JockerBuyBonusEng}`,
    //     href: "/instant-games/Jocker Buy Bonus"
    // },
    {
      src: `${config.imageV3Host}/static/mobile${theme.image.BurningIceEng}`,
      href: "/instant-games/BurningIce"
    },
    // {
    //     src: `${config.imageV3Host}/static/mobile${theme.image.MineIslandEng}`,
    //     href: "/instant-games/Mine Island"
    // },
    // {
    //   src: `${config.imageV3Host}/static/mobile${theme.image.burningIce40Eng}`,
    //   href: "/instant-games/BurningIce40"
    // },
  ];

  const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

  return (
    <>
      <InnerPromoWrapper>
        <SmartSoftContentWrapper>
          <SmartSoftTopSec>
            <SmartSoftTopLeftText>
              <h4>{domain} introduces free Spins on your</h4>
              <h4> favorite Smart Soft games</h4>
            </SmartSoftTopLeftText>
            <SmartSoftTopRightText>
              <h4>Get Set</h4>
              <h4>To <span>Win!</span></h4>
            </SmartSoftTopRightText>
          </SmartSoftTopSec>
          <HorizontalScrollWrapper>
            <HorizontalScroll>
              {
                gameImages.map((item: any) => {
                  return (
                    <SmartSoftGames>
                      <LazyLoadImage
                        src={item.src}
                        alt="instantGames"
                        effect="none"
                        onClick={() => navigate(item.href)}
                        placeholder={<PlaceholderImage />}
                        />
                    </SmartSoftGames>
                  )
                })
              }
            </HorizontalScroll>
          <ScrollBarTrack></ScrollBarTrack>
          </HorizontalScrollWrapper>
          <SmartSoftCenterText>
            <p>
              Games that thrill you and keeps you on the edgse. Brace yourself for a free ride ,  a ride where you spin money. A random free spin awaits
            </p>
            <p>you this March!</p>
          </SmartSoftCenterText>
          <SmartSoftCenterText>
            <h4>Embark on a spinning adventure</h4>
            <span className="underlines"></span>
          </SmartSoftCenterText>
          <SmartSoftSecBoxWrap>
            <SmartSoftSecondSecBox>
              <SmartSoftSecondSecBoxTop>
                <LazyLoadImage
                  src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepOne}`}
                  alt="jetxPromoStepOne"
                  effect="none"
                  placeholder={<PlaceholderImage />}
                />
                <span className="underlines"></span>
                <h3>Free Spins</h3>
              </SmartSoftSecondSecBoxTop>
              <p>
                Earn free spins by playing Smart Soft games and placing bets. Randomly receive a free prize spin. Random players who play and place a bet on Smart Soft games , will get a free prize spin only on {domain}!
              </p>
            </SmartSoftSecondSecBox>

            <SmartSoftSecondSecBox className="yellowBg">
              <SmartSoftSecondSecBoxTop>
                <LazyLoadImage
                  src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepTwo}`}
                  alt="jetxPromoStepTwo"
                  effect="none"
                  placeholder={<PlaceholderImage />}
                />
                <span className="underlines"></span>
                <h3>Bet and Win</h3>
              </SmartSoftSecondSecBoxTop>
              <p>
                Place a minimum bet of ₹ 180 to earn a random free spin and win from ₹ 450 to ₹ 2.7 lakh.{" "}
              </p>
            </SmartSoftSecondSecBox>

            <SmartSoftSecondSecBox className="bluewBg">
              <SmartSoftSecondSecBoxTop>
                <LazyLoadImage
                  src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepThree}`}
                  alt="jetxPromoStepThree"
                  effect="none"
                  placeholder={<PlaceholderImage />}
                />
                <span className="underlines"></span>
                <h3>Fast Fingers</h3>
              </SmartSoftSecondSecBoxTop>
              <p>
                Players will have only 30 sec to spin the wheel and win prizes.{" "}
              </p>
            </SmartSoftSecondSecBox>
          </SmartSoftSecBoxWrap>
          <SmartSoftCRThirdSec>
            <h2>Smart Soft – Spin & Win Fest!</h2>
            <span className="underlines"></span>
            <LazyLoadImage
              src={`${config.imageV3Host}/static/cashsite/${theme.image.smartSoftPromoIllus}`}
              alt="smartSoftPromoIllus"
              effect="none"
              placeholder={<PlaceholderImage />}
            />
          </SmartSoftCRThirdSec>
          <SmartSoftCRFinalSec>
            <SmartSoftCRFinalSecLeft>
              <LazyLoadImage
                src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane2}`}
                alt="jetxPromoPlane2"
                effect="none"
                placeholder={<PlaceholderImage />}
              />
            </SmartSoftCRFinalSecLeft>
            <SmartSoftCRFinalSecRight>
              <SmartSoftCRTermsTitle>
                <LazyLoadImage
                  src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoTC}`}
                  alt="smartSoftPromoT&C"
                  effect="none"
                  placeholder={<PlaceholderImage />}
                />
                <h6>Terms & Conditions</h6>
              </SmartSoftCRTermsTitle>
              <SmartSoftTermsBody>
                <ol>
                  <li>
                    This promotion is valid for all new & existing players.
                  </li>
                  <li>
                    This promotion is valid from 2nd June 2024 to 29th June 2024. 
                  </li>
                  <li>Each Player can only claim the bonus once during promotional period.</li>
                  <li>
                    Minimum bet amount to earn free spins is ₹ 180.
                  </li>
                  <li>
                    Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.
                  </li>
                  <li>
                    Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. BaaziAdda reserves the right to disqualify players if collusion or any foul play takes place in the promotion.
                  </li>
                  <li>
                    {domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.
                  </li>
                  <li>{domain} General Terms & Conditions apply.</li>
                </ol>
              </SmartSoftTermsBody>
            </SmartSoftCRFinalSecRight>
          </SmartSoftCRFinalSec>
        </SmartSoftContentWrapper>
      </InnerPromoWrapper>
      <FooterContainer />
    </>
  );
};

export default withTheme(withAppContext(SmartsoftSpinPromo));

//Styled Components

export const InnerPromoWrapper = styled.div`
  width: 98%;
  height: auto;
  border: 1px solid white;
  display: flex;
  border-radius: 1px;
  background: rgba(111, 111, 111, 0.5);
  margin: 0em 1.2em;
  -webkit-backdrop-filter: brightness(0.5);
  backdrop-filter: brightness(0.5);
`;

export const SmartSoftContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${config.imageV3Host}/static/cashsite/${theme("image.SmartsoftSpinPromoInnerBg")});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  .lazy-load-image-background {
    display: flex !important;
    justify-content: center !important;
  }
`;

const SmartSoftTopSec = styled.div`
  height: 263px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SmartSoftTopLeftText = styled.div`
  padding-left: 2.93%;
  padding-top: 4.05%;

  & h4 {
    color: #fede11;
    font-size: 26px;
    font-style: italic;
    font-weight: 800;
    line-height: 32.6px;
    text-transform: capitalize;
    text-align: left;
    margin: 5px 0px;
  }
`;

const SmartSoftTopRightText = styled.div`
  margin-top: 7.1%;
  margin-right: 12.17%;
  align-self: flex-end;

  & h4 {
    margin: 5px 0px;
    color: #FFF;
    font-size: 43.841px;
    font-style: italic;
    font-weight: 800;
    line-height: 39.249px; /* 89.524% */
    text-transform: capitalize;
  }
  & span {
    color: #fede11;
  }
`;

const HorizontalScrollWrapper = styled.div`
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 100px;
    position: relative;
`;

const HorizontalScroll = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  height: 355px;
  width: 29.8%;
  justify-self: center;
  align-self: flex-start;
  margin-left: 2.93%;  
  overflow: auto;
  white-space: nowrap;
  z-index: 100;

  ::before {
        content: '';
        background: linear-gradient(270deg, rgba(7, 11, 20, 0.00) 0%, #111111 100%);
        width: 28px;
        height: 67%;
        position: absolute;
        left: 2.71%;
    }

    ::after {
        content: '';
        background: linear-gradient(90deg, rgba(7, 11, 20, 0.00) 0%, #111111 100%);
        width: 23px;
        height: 67%;
        position: absolute;
        left: calc(29.8% + 1.8%);
    }

::-webkit-scrollbar {
  height: 1.5rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 transparent; 
  border-radius: 10px;

}
 
::-webkit-scrollbar-thumb {
  border-radius: 22px;
  background: linear-gradient(180deg, #F370C5 0.26%, #940061 125.2%);
  height: 20px;
  width: 10px;
}

& img {
        width: 118.37px;
        height: 137.97px;
        border: 0.787px solid #EBA02A;
        object-fit: fill;
    }
`;

const ScrollBarTrack = styled.div `
  width: 23%;
  height: 1px;
  background-color: #0EC2FF;
  position: absolute;
  bottom: 110px;
  left: 5.91%
`;

const SmartSoftGames = styled.div`
    margin: 7.84px;
    cursor: pointer;
`;


export const SmartSoftCenterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 2rem 0rem;
  & p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.5%; /* 17.99px */
    margin: 0;
    padding: 0;
  }

  & h4 {
    margin-top: 34px;
    margin-bottom: 0px;
    color: #fede11;
    text-align: center;
    font-size: 23px;
    font-style: italic;
    font-weight: 800;
    line-height: 25.5px; /* 110.87% */
    text-transform: capitalize;
  }

  & .underlines {
    width: 4.5em;
    height: 2px;
    background-color: #fede11;
    margin-top: 1em;
    text-align: center;
  }
`;

const SmartSoftSecBoxWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  z-index: 1;
`;

const SmartSoftSecondSecBox = styled.div`
  width: 25%;
  padding: 2em 2.5em;
  position: relative;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0em;
  gap: 15px;
  background: #06111D;

  ::before {
    content: '';
    position: absolute;
    top: 0; bottom: -2px;
    right: -2px; left: -2px;
    background: linear-gradient(360deg, #894D8B, #06111D 80%);
    border-radius: 35px;
    z-index: -1;
  }

  &.yellowBg {
    ::before {
      content: '';
      position: absolute;
      top: 0; bottom: -2px;
      right: -2px; left: -2px;
      background: linear-gradient(360deg, #FEDE11, #06111D 80%);
      border-radius: 35px;
      z-index: -1;
    }

    & h3 {
      color: #fede11 !important;
    }

    & .underlines {
      background-color: #fede11 !important;
    }
  } 

  &.bluewBg {
    ::before {
      content: '';
      position: absolute;
      top: 0; bottom: -2px;
      right: -2px; left: -2px;
      background: linear-gradient(360deg, #6FDBCB, #06111D 80%);
      border-radius: 35px;
      z-index: -1;
    }

    & h3 {
      color: #6fdbcb !important;
    }

    & .underlines {
      background-color: #6fdbcb !important;
    }
  }

  & p {
    margin-top: 0;
    color: #fff !important;
    text-align: justify;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 128.5%; /* 1.12438rem */
  }
`;

const SmartSoftSecondSecBoxTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;

  & h3 {
    margin: 3px 0px;
    color: #e664bc;
    font-size: 23px;
    font-style: italic;
    font-weight: 800;
    line-height: 25.5px; /* 110.87% */
    text-transform: capitalize;
  }

  & .underlines {
    width: 3px;
    height: 3em;
    background-color: #e664bc;
    text-align: center;
  }
`;
const SmartSoftCRThirdSec = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 2rem 0rem;
  margin-top: 75px;

  & .underlines {
    width: 5.5em;
    height: 2px;
    background-color: #fede11;
    margin-top: 1em;
  }

  & h2 {
    color: #fede11;
    text-align: center;
    font-size: 1.4375rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1.59375rem; /* 110.87% */
    text-transform: capitalize;
    margin: 3px 0px;
  }

  & img {
    width: 100%;
    height: auto;
  }
`;

const SmartSoftCRFinalSec = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 2em 0em;
  position: relative;
  padding-right: 12%;
  margin-top: 17%;
`;

const SmartSoftCRFinalSecLeft = styled.div`
  width: 40%;
  height: auto;
  position: absolute;
  left: 14vw;
  top: -95px;
  z-index: 100;

  & img {
    width: 750px;
    height: auto;
  }

  @media only screen and (min-width: 1500px) {
    left: 20vw;
  }

  @media only screen and (min-width: 1900px) {
    left: 24vw;
  }
`;

const SmartSoftCRFinalSecRight = styled.div`
  width: 40%;
  height: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: end;
  background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat, radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat, radial-gradient(circle at 100% 0, #030303 0, #050505 33px, transparent 33px) 0% 100%/35px 35px no-repeat, radial-gradient(circle at 0 0, #000000 0, #000000 33px, transparent 33px) 100% 100%/35px 35px no-repeat, linear-gradient(#0f1655, #000000) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat, linear-gradient(#0f1655, #000000) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat, linear-gradient(transparent 12%, #f273c4 100%);
  border-radius: 35px;
  backdrop-filter: blur(40.39555358886719px);
`;

const SmartSoftCRTermsTitle = styled.div`
  padding: 1em;

  & img {
    width: 100%;
    height: auto;
  }

  h6 {
    color: #fff;
    text-align: center;
    font-size: 1.10781rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.48819rem; /* 134.333% */
    letter-spacing: 0.09969rem;
    text-transform: capitalize;
    margin: 3px 0px;
  }
`;
const SmartSoftTermsBody = styled.div`
  width: 100%;
  height: auto;

  & ol {
    padding: 1em 2em;
    margin: 0px;
  }

  & li {
    color: #fff;
    text-align: justify;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.035rem;
    text-transform: capitalize;
    margin: 15px 0px;
  }
`;
